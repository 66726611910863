
<template>
  <en-drawer :model-value="modelValue" title="批量收款" direction="btt" size="70%" @close="$emit('update:model-value', false)">
    <en-form :model="form.data" :ref="setRef('form')" :loading="form.loading" class="grid grid-cols-5 gap-6">
      <en-form-item label="担保人">
        <select-maintain
          v-model="form.data.creditBy"
          :ajax="{
            action: 'GET /enocloud/common/hint/:hintTypeStr',
            params: (params) => (params.paths = ['RACB'])
          }"
          :props="{ label: 'name', value: 'name' }"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="收银时间">
        <en-date-picker v-model="form.data.businessDatetime" format="YYYY-MM-DD" class="w-full"></en-date-picker>
      </en-form-item>
      <en-form-item label="备注">
        <en-input v-model="form.data.comment"></en-input>
      </en-form-item>
      <en-form-item label="收款方式">
        <select-maintain
          v-model="form.data.paymentMethod"
          :ajax="{ action: 'GET /enocloud/common/hint/:hintTypeStr', params: (params) => (params.paths = ['PAMTMTD']) }"
          :props="{ label: 'name', value: 'name' }"
          class="w-full"
        ></select-maintain>
      </en-form-item>
    </en-form>

    <!-- <div class="flex items-center gap-4 text-sm">
      <span>结算金额：{{ total.amount }}</span>
      <span>冲减定金：{{ total.receiptAdvanceDeposit }}</span>
      <span>优惠减免：{{ total.adjustingAmount }}</span>
    </div> -->
    <en-table :data="form.data.receiptReceivables" :rules="form.rules" :ref="setRef('form')" :loading="form.loading">
      <en-table-column label="操作">
        <template #default="{ $index }">
          <en-button type="primary" text @click="table.delete.click($index)">删除</en-button>
        </template>
      </en-table-column>
      <en-table-column label="相关单号" prop="receivable.serialNo"> </en-table-column>
      <en-table-column label="业务类型" prop="receivable.type.message"> </en-table-column>
      <!-- <en-table-column label="应收款" prop="receivableAmount"> </en-table-column> -->
      <en-table-column label="结算金额">
        <template #default="{ row }: { row: EnocloudSettlementDefinitions['ReceiptReceivableDto'] }">
          <en-input-number v-model="row.amount" @change="table.amount.change"></en-input-number>
        </template>
      </en-table-column>
      <!-- <en-table-column label="优惠减免">
        <template #default="{ row }: { row: EnocloudSettlementDefinitions['ReceiptReceivableDto'] }">
          <en-input-number v-model="row.receivable.adjustingAmount" @change="table.adjustingAmount.change"></en-input-number>
        </template>
      </en-table-column> -->
      <en-table-column label="冲减预收">
        <template #default="{ row }: { row: EnocloudSettlementDefinitions['ReceiptReceivableDto'] }">
          <en-input-number v-model="row.receivable.receiptAdvanceDeposit"></en-input-number>
        </template>
      </en-table-column>
      <en-table-column label="备注" prop="receivable.comment"> </en-table-column>
    </en-table>

    <template #footer>
      <button-ajax :method="footer.receiptDetail.click">选择收款单据</button-ajax>
      <button-ajax :method="footer.receipt.click" >收 款</button-ajax>
    </template>
  </en-drawer>

  <more-receivable-detail
    v-model="moreReceivableDetail.visible"
    :selectedData="form.data.receiptReceivables"
    @confirm="moreReceivableDetail.confirm"
    :creditBy="moreReceivableDetail.creditBy"
  ></more-receivable-detail>
</template>

<script lang="ts">
import MoreReceivableDetail from '@settlement/components/more-receivable-detail.vue'
import { calculator } from '@enocloud/utils'
import dayjs from 'dayjs'
import { chain } from 'lodash-es'
const formDataInit = (): EnocloudSettlementDefinitions['ReceiptDto']=> {
  const data = { type: { code: 'REC', message: '', type: '', description: '' }, businessDatetime: dayjs().format('YYYY-MM-DDTHH:mm:ss') } as any
  return data
}
export default factory({
  components: { MoreReceivableDetail },
  props: {
    modelValue: Boolean,
    data: Object as PropType<EnocloudServiceDefinitions['ServiceReservationDto']>
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean',
    confirm: () => true,
    receipt: (id: number | undefined) => ['number', 'undefinded'].includes(typeof id)
  },

  watch: {
    modelValue: {
      async handler(row: any) {
        if (!this.modelValue) {
          this.form.data = formDataInit()
        }
      }
    }
  },

  config: {
    children: {
      form: {
        data: formDataInit(),
        ajax: {
          get: {
            action: 'GET /enocloud/settlement/receivable/:receivableId',
            data: 'object',
            loading: true,
            init: true,
            params(params) {
              params.paths = [this.form.data.id]
 
            }
          },
          submit: {
            action: 'POST /enocloud/settlement/receipt/batch',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = this.form.data
            }
      
          }
        }
      },
      table: {
        data: [],
        children: {
          adjustingAmount: {
            change(value: number) {
              this.total.amount = this.total.amount - value
            }
          },
          amount: {
            change(value: number) {}
          }
        },

        delete: {
          click(index: number) {
            this.form.data.receiptReceivables.splice(index, 1)
          }
        }
      },
      moreReceivableDetail: {
        visible: false,
        async confirm(tabledata: EnocloudSettlementDefinitions['ReceivableQueryDto'][]) {
          this.form.data.receiptReceivables = []
          for (const item of tabledata) {
            const receivable = (await this.ajax('GET /enocloud/settlement/receivable/:receivableId', { paths: [item.id] })).data[0]
            this.form.data.receiptReceivables.push({
              amount: 0,
              receiptAdvanceDeposit: 0,
              receivable,
              receipt:0,
              chargeableDeposit:0
            })
          }
        }
      },
      footer: {
        receiptDetail: {
          async click() {
            this.moreReceivableDetail.payerId = this.form.data.payer?.id
           this.moreReceivableDetail.creditBy =  this.form.data.creditBy 
            this.moreReceivableDetail.visible = true
     
          }
        },
        receipt: {
          async click() {
            this.form.submit()
            this.emit('update:model-value', false)
            this.emit('confirm')
          }
        }
      }
    }
  },


  // computed: {
  //   total() {
  //     const amount = this.form.data.receiptReceivables.reduce((amount, item) => calculator.add(amount, item.amount), 0)
  //     const adjustingAmount = this.form.data.receiptReceivables.reduce(
  //       (adjustingAmount, item) => calculator.add(adjustingAmount, item.adjustingAmount),
  //       0
  //     )
  //     const receiptAdvanceDeposit = this.form.data.receiptReceivables.reduce(
  //       (receiptAdvanceDeposit, item) => calculator.add(receiptAdvanceDeposit, item.receiptAdvanceDeposit),
  //       0
  //     )

  //     const summary = amount - adjustingAmount
  //     return {
  //       amount,
  //       adjustingAmount,
  //       receiptAdvanceDeposit,
  //       summary
  //     }
  //   }
  // }
})
</script>
